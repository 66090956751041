const env = process.env.REACT_APP_ENVIRONMENT || "dev";

const envConfig = require(`./config.${env}.json`);

const config: {
    accessTokenName: string;
    authenticateApiUrl: string;
    authorizationCodeName: string;
    authWebUrl: string;
    cloudApiBaseUrl: string;
    connectWebUrl: string;
    databrokerApiUrl: string;
    deathEngineApiUrl: string;
    emailApiUrl: string;
    fasanoBucketName: string;
    fasanoClientWebUrl: string;
    fasanoWebUrl: string;
    fileApiUrl: string;
    fasanoHelpEmail: string;
    idleTimeout: number;
    leOrderApiUrl: string;
    fasanoNoReplyEmail: string;
    leCalculationApiUrl: string;
    servicingReportApiUrl: string;
    servicingWebUrl: string;
    siteMapApiUrl: string;
    thumbnailBucketName: string;
    twentyFirstLEClientID: string;
    twentyFirstLEWebUrl: string;
    twentyFirstLEHelpEmail: string;
    underwritingApiBaseUrl: string;
    uwDateToClientApiUrl: string;
    webAppBucketName: string;
} = {
    accessTokenName: "access_token",
    idleTimeout: 1800000,
    authorizationCodeName: "authorization_code",
    fasanoBucketName: "fasano",
    webAppBucketName: "webApp",
    thumbnailBucketName: "thumbnail",
    ...envConfig
};

export default config;
